import request from '../utils/request'

export function getDistricts(data) {
    return request({
        url: '/api/district',
        method: 'get',
        params: data
    })
}

export function getDistrictsCity(id) {
    return request({
        url: '/api/district-city/'+id,
        method: 'get'
    })
}

export function getDistrictsId(id) {
    return request({
        url: '/api/district/'+id,
        method: 'get',
    })
}

export function postDistricts(data) {
    return request({
        url: '/api/district',
        method: 'post',
        data
    })
}

export function putDistricts(data, id) {
    return request({
        url: '/api/district/'+id,
        method: 'put',
        data
    })
}

export function deleteDistricts(id) {
    return request({
        url: '/api/district/'+id,
        method: 'delete'
    })
}
